.loadingContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .loadingIndicator{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
.pageContent {
  display:flex;
  flex:3;
  .dashbbord {
    display: flex;
    flex-direction: column;
    flex: 1;
    .dashboardItem{
      display: flex;
      flex:1;
      width: 50%;
      flex-direction: column;      
    }
  }
  

}
.adminMenu {
  display: flex;
  flex-direction: row;
  flex:0;
}

.pageHeader {
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  flex:1;
  .pageHeaderItem {
    margin: 1rem;
    .greeting {
      flex:1
    }
  }
}