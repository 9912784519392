.pageContainer{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  
  .pageHeader {
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    flex:1;
    .pageHeaderItem {
      margin: 1rem;
      .greeting {
        flex:1
      }
    }
  }
  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    margin: 0;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75%;
    width: 75%;
    max-width: 20rem;
    background-color: #f5f5f5;
    .description {
      display: flex;
      margin: 0.5rem;
      height: 4rem;
    }
    .inputField{
      margin: 1rem;
      height: 6rem;
    }
    .btnLogin{
      
      margin: 0.5rem;
      
      margin-left: auto;
    }
  }
}
iframe {
  border: none;
  width: 100%;
  flex:1;
}
.capitalize {
  text-transform: capitalize;
}
/* Viewports between 320px and 480px wide */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  
}